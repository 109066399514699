import { render, staticRenderFns } from "./UnsentMessages.vue?vue&type=template&id=778a4458&scoped=true"
import script from "./UnsentMessages.vue?vue&type=script&lang=js"
export * from "./UnsentMessages.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "778a4458",
  null
  
)

export default component.exports